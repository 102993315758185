import styles from './DiscountCampaignBanner.module.scss';
import { useMemo } from 'react';
import { track } from '@amplitude/analytics-browser';
import classNames from 'classnames';
import { YEAR_END_2024_EVENT } from 'common/utils';
import useGlobalStore from 'store/global';
import { getUserYearEndEvent } from 'common/utils/user';
import useStore from 'store/timeline';

const randomBgUrl = () => {
  const random = Math.floor(Math.random() * 2);
  if (random === 1) return '/images/banners/year-end-2024-event-1.jpg';
  return '/images/banners/year-end-2024-event-2.jpg';
};

const DiscountCampaignBanner = ({
  fallbackElement,
  className,
}: {
  fallbackElement?: JSX.Element;
  className?: string;
}) => {
  const bgUrl = useMemo(() => {
    return randomBgUrl();
  }, []);
  const showDiscountCreatorsDialog = useGlobalStore(
    (state) => state.showDiscountCreatorsDialog
  );
  const currentUser = useStore((state) => state.currentUser);
  const discountPrimeSubEvent = getUserYearEndEvent(currentUser);
  if (!discountPrimeSubEvent || !bgUrl)
    return fallbackElement ? fallbackElement : <></>;
  return (
    <div
      className={classNames(styles.banner, className ?? '')}
      onClick={() => {
        track('Click Top Banner', { banner_type: YEAR_END_2024_EVENT });
        showDiscountCreatorsDialog(true);
      }}
    >
      <img src={bgUrl} width="100%" />
    </div>
  );
};

export default DiscountCampaignBanner;
