import { MissionCode, MissionTaskStatus } from 'service/user';
import { addIgMissionBannerEvent } from 'common/utils/user';
import useStore from 'store/timeline';
import styles from './IGBanner.module.scss';
import { useMemo } from 'react';
import { track } from '@amplitude/analytics-browser';
import classNames from 'classnames';

const randomBgUrl = (coinNumber: number) => {
  const random = Math.floor(Math.random() * 3);
  if (coinNumber === 20000) {
    return '/images/banners/ig-2man-coin-202411.jpg';
  } else {
    if (random === 1) return '/images/banners/ig_banner_1_pr.jpg';
    if (random === 2) return '/images/banners/ig_banner_2_pr.jpg';
    return '/images/banners/ig_banner_3_pr.jpg';
  }
};

const IGBanner = ({
  fallbackElement,
  className,
}: {
  fallbackElement?: JSX.Element;
  className?: string;
}) => {
  const allMissions = useStore((state) => state.allMissions);
  const igMission = useMemo(() => {
    return allMissions.find(
      (mission) => mission.code === MissionCode.IGCreateAccount
    );
  }, [allMissions]);
  const showingBanner =
    igMission &&
    [MissionTaskStatus.NotYet, MissionTaskStatus.InProgress].includes(
      igMission?.current_task?.status
    );
  const bgUrl = useMemo(() => {
    return randomBgUrl(igMission?.coin);
  }, [igMission?.coin]);

  if (!showingBanner || !bgUrl)
    return fallbackElement ? fallbackElement : <></>;
  return (
    <div
      className={classNames(styles.banner, className ?? '')}
      onClick={() => {
        track('Click Top Banner', { banner_type: 'ig_banner' });
        addIgMissionBannerEvent('banner');
        window.open(igMission?.link, '_blank')?.focus();
      }}
    >
      <img src={bgUrl} width="100%" />
    </div>
  );
};

export default IGBanner;
