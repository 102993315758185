import Image from 'next/image';
import { useState } from 'react';
import Icon from 'components/common/Icon';
import useGlobalStore from 'store/global';
import styles from './CoinBanner.module.scss';
import classNames from 'classnames';
import { track } from '@amplitude/analytics-browser';
import useStore from 'store/timeline';
import { INVITE_USER_COIN_EVENT } from 'common/utils';

const colorName = () => {
  const random = Math.floor(Math.random() * 3);
  // const random = Math.floor(Math.random() * 7);
  if (random === 1) return 'orange';
  if (random === 2) return 'blue';
  return 'purple';
  // if (index === 4) return 'green';
  // if (index === 5) return 'pink';
  // if (index === 6) return 'orange1';
  // if (index === 0) return 'blue1';
  // return '';
};

const CoinBanner = () => {
  const currentUser = useStore((state) => state.currentUser);
  const inviteEvent = currentUser?.applicable_promotions?.find(
    (event) => event.type === INVITE_USER_COIN_EVENT
  );
  const [color] = useState(colorName());
  const showInviteFriendDialog = useGlobalStore(
    (state) => state.showInviteFriendDialog
  );
  if (!inviteEvent) return <></>;
  return (
    <>
      <div
        className={classNames(styles.coinBannerWrapper, styles[color])}
        onClick={() => {
          track('Click Top Banner', { banner_type: 'coin_banner' });
          showInviteFriendDialog(true);
        }}
      >
        <div className={styles.firstImage}>
          <Image src="/images/icons/coin-1.png" width={40} height={40} />
        </div>
        <div className={styles.content}>
          <span className={styles.text}>
            {inviteEvent.params?.referral_reward_referred_user?.toLocaleString() ||
              400}
            コイン
          </span>
          <span className={styles.subText}>無料でもらえる。</span>
          <span className={styles.smallText}>
            <span>
              <Icon name="forward" height={12} width={12} />
            </span>
            ここをクリック
          </span>
        </div>
        <div className={styles.lastImage}>
          <Image src="/images/icons/coin.png" width={40} height={40} />
        </div>
      </div>
    </>
  );
};

export default CoinBanner;
