import { useState } from 'react';
import useGlobalStore from 'store/global';
import styles from './AnimeAlarmBanner.module.scss';
import classNames from 'classnames';
import useStore from 'store/timeline';
import { addAnimeTrackingEvent } from 'common/utils/anime';
import { EventAction, EventComponent } from 'common/utils/pp_tracking';
import { track } from '@amplitude/analytics-browser';

const randomColor = () => {
  const random = Math.floor(Math.random() * 3);
  if (random === 1) return 'blue';
  if (random === 2) return 'purple';
  return '';
};

const AnimeAlarmBanner = () => {
  const [currentColor] = useState(randomColor());
  const showAnimeOnboardingDialog = useGlobalStore(
    (state) => state.showAnimeOnboardingDialog
  );
  const setUserClickedAnimeIcon = useGlobalStore(
    (state) => state.setUserClickedAnimeIcon
  );
  const currentUser = useStore((state) => state.currentUser);
  if (!currentUser) return <></>;
  return (
    <div
      className={classNames(styles.animeAlarmsBanner, {
        [styles[currentColor]]: !!currentColor,
      })}
      onClick={() => {
        track('Click Top Banner', { banner_type: 'ai_banner' });
        if (
          currentUser?.anime_character_onboarding ||
          !currentUser?.user_character
        ) {
          showAnimeOnboardingDialog(true);
        }
        if (currentUser?.user_character) {
          setUserClickedAnimeIcon(true);
        }
        addAnimeTrackingEvent({
          action: EventAction.Click,
          component: EventComponent.AnimeFunctionBanner,
          position: 'banner',
        });
      }}
    >
      <div className={styles.content}>
        <span className={styles.text}>AIキャラクターに新要素が追加！</span>
        <span className={styles.smallText}>無料機能も拡大！</span>
      </div>
      <div className={styles.lastImage}>
        <img
          src="/images/banners/anime_banner/anime-banner-characters-1.png"
          className={styles.image}
        />
      </div>
    </div>
  );
};

export default AnimeAlarmBanner;
